import { Link } from "react-router-dom"

export default function Footer() {
    return (
        <>
            { /* Footer */ }
            <div className="footer section">
                <div className="content">
                    <div className="footer_con">
                        <div className="footer_con__title title">SOBRE NOSOTROS</div>
                        <a href="https://ciomc.org/#programas" title="Programas" target="_blank" className="footer_con__el">Programas de conservación</a>
                        <a href="mailto:medios@granacuario.com" title="Enviar correo" className="footer_con__el">Medios de comunicación</a>
                        <a href="mailto:rh@granacuario.com" title="Enviar correo" className="footer_con__el">Bolsa de trabajo</a>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">CONÉCTATE CON NOSOTROS</div>
                        <div className="footer_con_social">
                            <a href="https://www.instagram.com/granacuariomazatlan/" title="Instagram" target="_blank" className="footer_con__el"><img src="/images/footer/ins.svg" alt="Instagram Icon" title="Instagram Icon"/></a>
                            <a href="https://www.facebook.com/granacuariomazatlan" title="Facebook" target="_blank" className="footer_con__el"><img src="/images/footer/fb.svg" alt="Facebook Icon" title="Facebook Icon"/></a>
                            <a href="https://www.youtube.com/@granacuariomazatlan" title="Youtube" target="_blank" className="footer_con__el"><img src="/images/youtube.svg" alt="Youtube Icon" title="Youtube Icon"/></a>
                            <a href="https://x.com/GranAcuarioMzt" title="X" target="_blank" className="footer_con__el"><img src="/images/x.svg" alt="X Icon" title="X Icon"/></a>
                            <a href="https://www.tiktok.com/@granacuariomazatlan" title="Tiktok" target="_blank" className="footer_con__el"><img src="/images/tiktok.svg" alt="Tiktok Icon" title="Tiktok Icon"/></a>
                            <a href="https://www.google.com/maps/place/Acuario+%22Mar+de+Cort%C3%A9s%22/@23.229808,-106.4287194,17.79z/data=!4m6!3m5!1s0x869f53312f8789e9:0xef0199571d862256!8m2!3d23.2305467!4d-106.4282474!16s%2Fg%2F11k6z2w9ct?coh=164777&entry=tt&shorturl=1" title="Ubicación" target="_blank" className="footer_con__el"><img src="/images/footer/map.svg" alt="Map Icon" title="Map Icon"/></a>
                        </div>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">SERVICIO DE ASISTENCIA</div>
                        <Link to="/contacto" title="Contacto" className="footer_con__el">Contáctanos</Link>
                        <Link to="/faq" title="Preguntas frecuentes" className="footer_con__el">Preguntas frecuentes</Link>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">DIRECCIÓN Y TELÉFONO</div>
                        <a href="https://goo.gl/maps/nrpPfrAL1cZewPbv8" title="Ubicación" target="_blank" className="footer_con__el">Av. Leonismo Internacional SN, Tellería, 82017 Mazatlán, Sinaloa.</a>
                        <a href="tel:6696890501" title="Llamar" className="footer_con__el">Tel. (669) 689 0501</a>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">INSTITUCIONES</div>
                        <div className="footer_con_fund">
                            <a href="https://ciomc.org/" title="CIOMC" target="_blank" className="footer_con__el"><img src="/images/footer/ciomc.png" srcSet="/images/footer/ciomc.webp" alt="CIOMC logo" title="CIOMC logo"/></a>
                            <a href="https://www.ciad.mx/" title="CIAD" target="_blank" className="footer_con__el"><img src="/images/footer/ciad.png" srcSet="/images/footer/ciad.webp" alt="CIAD logo" title="CIAD logo"/></a>
                            <a href="https://www.icmyl.unam.mx/" title="ICMYL" target="_blank" className="footer_con__el"><img src="/images/footer/icml.png" srcSet="/images/footer/icml.webp" alt="ICML logo" title="ICML logo"/></a>
                        </div>
                    </div>
                    <div className="footer_con">
                        <div className="footer_con__title title">PATROCINADORES</div>
                        <div className="footer_con_fund_dos">
                            <div className="footer_con__el"><img src="/images/footer/jumex_Logo.png" srcSet="/images/footer/jumex_Logo.webp" alt="JUMEX logo" title="JUMEX logo"/></div>
                            <div className="footer_con__el"><img src="/images/footer/coppel_Logo.png" srcSet="/images/footer/coppel_Logo.webp" alt="COPPEL logo" title="COPPEL logo"/></div>
                            <div className="footer_con__el"><img src="/images/footer/logo_andamios.png" srcSet="/images/footer/logo_andamios.webp" alt="ANDAMIOS" title="ANDAMIOS"/></div>
                        </div>
                    </div>
                </div>
            </div>
            { /* Copyright */ }
            <div className="copy section"><div className="content">© 2024 Todos los derechos reservados. <a href="/images/tyc.pdf" title="TYC" target="_blank">Términos y condiciones.</a> <a href="/images/reglamento.pdf" title="Reglamento" target="_blank">Reglamento Gran Acuario Mazatlán.</a> <a href="https://www.proyecta.com.mx" title="Proyecta Igniting link" target="_blank">Powered by Proyecta Igniting.</a></div></div>
        </>
    )
}